import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import SideMenu from '../SideMenu/SideMenu';
import { useHttpService } from '../../hooks/UseHttpService';
import { useAppContext } from '../../context/AppContext';


const Explore: React.FC = () => {

    const { redirectIfNoUser } = useAppContext();
    const http = useHttpService();
    const location = useLocation();
    const [recentlyViewed, setRecentlyViewed] = useState([]);

    useEffect(() => {
        redirectIfNoUser();
        const timer = setTimeout(() => {
            getMostRecentProperties();
            //TODO: try checking cache first
        }, 500);
        return () => clearTimeout(timer);
    }, [http, location.pathname])

    const getMostRecentProperties = async () => {
        http.get(`/viewedProperties?offset=0&limit=10&preview=true`)
            .then(res => {
                if (res.success) {
                    setRecentlyViewed(res.data.properties);
                    localStorage.setItem("recentlyViewed", JSON.stringify(res.data.properties));
                }
            })
    }

    return (
        <Layout>
            <SideMenu recentlyViewed={recentlyViewed} />
            <Layout style={{ paddingLeft: 'max(2%, 20px' }}>
                <Outlet />
            </Layout>
        </Layout>
    );
};

export default Explore;
