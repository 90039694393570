import { Card, Layout, Tabs } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { FC, useEffect, useState } from 'react';
import { useHttpService } from '../../hooks/UseHttpService';
import { TableCell, TableHeader } from '../../types/Table.def';
import { GenericTable } from '../GenericTable/GenericTable';
import SideMenu from '../SideMenu/SideMenu';
import { LoanDataProps, LoanDataType } from '../../types/LoanDataTypes.def';


const LoanData: FC<LoanDataProps> = ({type}) => {
    
    const http = useHttpService();
    
    const [saleObject, setSaleObject] = useState<{headers: TableHeader[], rows: TableCell[][]}>({headers: [], rows: []});
    const [titleObject, setTitleObject] = useState<{headers: TableHeader[], rows: TableCell[][]}>({headers: [], rows: []});
    const [recentlyViewed, setRecentlyViewed] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        switch (type) {
            case LoanDataType.TITLE:
                getTitleActivity();
                break;
            case LoanDataType.SALE:
                getSaleActivity();
                break;
            default:
                break;
        }
    }, [type])

    useEffect(() => {
        if(recentlyViewed.length === 0) {
            let cached = localStorage.getItem("recentlyViewed");
            if(cached) {
                setRecentlyViewed(JSON.parse(cached));
            } else {
                getMostRecentProperties();
            }
        }
    }, [])
    
    const getTitleActivity = async () => {
        setLoading(true);
        //&offset=0
        http.get('/workspace/titleActivity?limit=25')
        .then((res) => {
            setTitleObject(res.data);
        })
        .finally(() => {
            setLoading(false);
        })
    }
    
    const getSaleActivity = async () => {
        setLoading(true);
        //&offset=0
        http.get('/workspace/saleActivity?limit=25')
        .then((res) => {
            setSaleObject(res.data);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const getMostRecentProperties = async () => {
        http.get(`/viewedProperties?offset=0&limit=10&preview=true`)
            .then(res => {
                if (res.success) {
                    setRecentlyViewed(res.data.properties);
                    localStorage.setItem("recentlyViewed", JSON.stringify(res.data.properties));
                }
            })
    }

    return (
        <Layout>
            <SideMenu recentlyViewed={recentlyViewed} />
            <Content style={{padding: '16px 10px'}}>
                <Card style={{width: '98%', marginLeft: 'min(4%, 20px'}}>
                    <GenericTable data={type === LoanDataType.SALE ? saleObject : titleObject} loading={loading} />
                </Card>
            </Content>
        </Layout>
    );
};

export default LoanData;
