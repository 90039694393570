import React from 'react';
import { Button, Layout, Typography, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';

const { Header, Content } = Layout;
const { Title, Paragraph } = Typography;

const LandingPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Layout className="landing-layout">
            <Header className="landing-header">
                <div className="logo">TrueAVM</div>
                <Button onClick={() => navigate('/login')}>Sign in</Button>
            </Header>
            
            <Content>
                <div className="hero-section">
                    <div className="hero-content">
                        <Title level={1}>Real Estate Property Valuations Made Easy</Title>
                        <Paragraph>
                            TrueAVM is a web based software used by financial institutions 
                            to value properties, track title activity, and monitor properties that 
                            are on the market.
                        </Paragraph>
                        <Button type="primary" size="large" onClick={() => window.location.href = 'mailto:info@trueavm.com'}>
                            Contact
                        </Button>
                    </div>
                    <div className="hero-image">
                        <img style={{width: 'min(65vw, 400px)', height: 'min(65vw, 400px)', borderRadius: '50%'}} src="/img/avmvisual-min.png" alt="Neighborhood Image" />
                    </div>
                </div>

                <div className="feature-visual">
                    <img style={{width: '80%', height: 'min(80vh, 400px)', objectFit: 'cover'}} src="/img/neighborhood_graphic.png" alt="Neighborhood Image" />
                </div>

                <div className="description-section">
                    <Paragraph className="main-description">
                        Banks, credit unions, mortgage companies, and other real estate professionals use our software to 
                        quickly and cost effectively assess mortgage and HELOC applications for loan-to-value (LTV) 
                        ratios, track title activity for liens or ownership changes or other activity that may increase risk, 
                        and to monitor when properties are for sale to proactively reach out to customer and effectively 
                        prepare for adjustments.
                    </Paragraph>

                    <hr style={{width: '80%', marginTop: '50px', marginBottom: '50px'}}></hr>

                    <div className="features-grid">
                        <div className="feature-item">
                            <div className="feature-circle">TrueAVM</div>
                            <Paragraph>
                                Leverage our online edge Automated Valuation Model (AVM) for cost effective and fast loan 
                                underwriting, appraisal reconciliation, portfolio and regulatory compliance.
                            </Paragraph>
                        </div>

                        <div className="feature-item">
                            <div className="feature-circle">Title Monitor</div>
                            <Paragraph>
                                Track title activity to detect potential fraud, liens and ownership information, and as an early warning 
                                indicator of financial distress.
                            </Paragraph>
                        </div>

                        <div className="feature-item">
                            <div className="feature-circle">On the Market Monitor</div>
                            <Paragraph>
                                Monitor properties that are for sale to exercise portfolio retention and customer service, such as a 
                                new mortgage.
                            </Paragraph>
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
    );
};

export default LandingPage;