import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import BaseLayout from "./components/BaseLayout/BaseLayout";
import Home from "./components/Home/home";
import LandingPage from "./components/LandingPage/LandingPage";
import LoanData from "./components/LoanData/LoanData";
import Login from "./components/Login/login";
import Manage from "./components/Manage/Manage";
import ManageAccount from "./components/Manage/ManageAccount/ManageAccount";
import NotFound from "./components/NotFound/NotFound";
import PropertyPage from "./components/Property/PropertyPage";
import PropertySearch from "./components/PropertySearch/PropertySearch";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import ValueEstimator from "./components/ValueEstimator/ValueEstimator";
import { AppProvider } from "./context/AppContext";
import { LoanDataType } from "./types/LoanDataTypes.def";
import PropertySearchAndFilter from "./components/Explore/PropertySearchAndFilters/PropertySearchAndFilters";
import Explore from "./components/Explore/Explore";
        
//try to add loader to login route (redirectIfUser)? loader={redirectIfUser}

const isLoggedIn = localStorage.getItem('isLoggedIn');

const redirectIfUser = () => {
    if (isLoggedIn) {
        return <Navigate to="/home" />;
    }
    return <Navigate to="/" />;
}

export default (
    <BrowserRouter>
        <AppProvider>
            <Routes>
                <Route loader={redirectIfUser} path="/" element={<LandingPage />} />
                <Route loader={redirectIfUser} path="/login" element={<Login />} />
                <Route loader={redirectIfUser} path="/reset-password" element={<ResetPassword />} />
                <Route loader={redirectIfUser} path="/auth" element={<Login />} />

                <Route element={<BaseLayout />}>
                    <Route path="/explore" element={<Explore />}>
                        <Route path="properties" element={<PropertySearchAndFilter key="properties" dataType="properties" />} />
                        <Route path="saleActivity" element={<PropertySearchAndFilter key="saleActivity" dataType="saleActivity" />} />
                        <Route path="titleActivity" element={<PropertySearchAndFilter key="titleActivity" dataType="titleActivity" />} />
                    </Route>
                    <Route loader={redirectIfUser} path={"/home"} element={<Home />} />
                    <Route loader={redirectIfUser} path={"/property-search/:simProp?/:propertyId?"} element={<PropertySearch />} >
                        <Route loader={redirectIfUser} path={"view/:viewPropertyId"} element={<PropertyPage />} />
                    </Route>
                    <Route path={`/${LoanDataType.TITLE}`} element={<LoanData type={LoanDataType.TITLE}/>} />
                    <Route path={`/${LoanDataType.SALE}`} element={<LoanData type={LoanDataType.SALE}/>} />
                    <Route loader={redirectIfUser} path={"/manage"} element={<Manage />} >
                        <Route loader={redirectIfUser} index path={"/manage/account"} element={<ManageAccount />} />
                        <Route loader={redirectIfUser} path={'/manage/file-upload'} element={<NotFound />} />
                        <Route loader={redirectIfUser} path={'/manage/value-estimator'} element={<ValueEstimator />} />
                    </Route>
                    <Route path="*" element={isLoggedIn ? <NotFound /> : <Navigate to="/" />} />
                </Route>
            </Routes>
        </AppProvider>
    </BrowserRouter>
)