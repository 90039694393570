export interface PropertyFilter {
    type: "text" | "number" | "date" | "select" | "checkbox";
    key: string;
    label: string;
    value?: any[];
    minValue?: any;
    maxValue?: any;
    options?: PropertyFilterOption[];
    //TODO: add any fields necessary here for the filters page
}

export interface PropertyFilterOption {
    label: string,
    value: any,
    isSelected: boolean
}

export const PropertyFilters: PropertyFilter[] = [
    {
        type: "select",
        key: "propertyType",
        label: "Property Type",
        options: [
            {label: "Single Family", value: "singleFamily", isSelected: false},//TODO is this camel casing how the backend expects it?
            {label: "Condo", value: "condo", isSelected: false},
            {label: "Mobile", value: "mobile", isSelected: false},
            {label: "Recreational", value: "recreational", isSelected: false},
        ]
    },
    {
        type: "number",
        key: "sqft",
        label: "Sq. Ft.",
        minValue: null,
        maxValue: null
    },
    {
        type: "number",
        key: "beds",
        label: "Bedrooms",
        minValue: null,
        maxValue: null
    },
    {
        type: "number",
        key: "baths",
        label: "Bathrooms",
        minValue: null,
        maxValue: null
    }, 
    {
        type: "number",
        key: "yearBuilt",
        label: "Year Built",
        minValue: null,
        maxValue: null
    },
    {
        type: "number",
        key: "acres",
        label: "Acres",
        minValue: null,
        maxValue: null
    }, 
    {
        type: "select",
        key: "listStatus",
        label: "List Status",
        options: [
            {label: "Active", value: "active", isSelected: false},
            {label: "Auction", value: "auction", isSelected: false},
            {label: "Expired", value: "expired", isSelected: false},
            {label: "Inactive", value: "inactive", isSelected: false},
            {label: "Off-Market", value: "offMarket", isSelected: false},
            {label: "Sold", value: "sold", isSelected: false},
            {label: "Under Contract", value: "underContract", isSelected: false},
            {label: "Unknown", value: "unknown", isSelected: false},
            {label: "Withdrawn", value: "withdrawn", isSelected: false},
        ]
    },
    {
        type: "number",
        key: "listPrice",
        label: "List Price",
        minValue: null,
        maxValue: null
    },
    {
        type: "date",
        key: "listDate",
        label: "List Date",
        minValue: null,
        maxValue: null
    },
    {
        type: "select",
        key: "state",
        label: "State",
        options: [
            //TODO: states should be in DB
            // {label: "Alabama", value: "AL", isSelected: false},
            // {label: "Alaska", value: "AK", isSelected: false},
            // {label: "Arizona", value: "AZ", isSelected: false},
            // {label: "Arkansas", value: "AR", isSelected: false},
            // {label: "California", value: "CA", isSelected: false},
            // {label: "Colorado", value: "CO", isSelected: false},
            // {label: "Connecticut", value: "CT", isSelected: false},
            // {label: "Delaware", value: "DE", isSelected: false},
            // {label: "Florida", value: "FL", isSelected: false},
            // {label: "Georgia", value: "GA", isSelected: false},
            // {label: "Hawaii", value: "HI", isSelected: false},
            // {label: "Idaho", value: "ID", isSelected: false},
            // {label: "Illinois", value: "IL", isSelected: false},
            // {label: "Indiana", value: "IN", isSelected: false},
            // {label: "Iowa", value: "IA", isSelected: false},
            // {label: "Kansas", value: "KS", isSelected: false},
            // {label: "Kentucky", value: "KY", isSelected: false},
            // {label: "Louisiana", value: "LA", isSelected: false},
            // {label: "Maine", value: "ME", isSelected: false},
            // {label: "Maryland", value: "MD", isSelected: false},
            // {label: "Massachusetts", value: "MA", isSelected: false},
            // {label: "Michigan", value: "MI", isSelected: false},
            // {label: "Minnesota", value: "MN", isSelected: false},
            // {label: "Mississippi", value: "MS", isSelected: false},
            // {label: "Missouri", value: "MO", isSelected: false},
            // {label: "Montana", value: "MT", isSelected: false},
            // {label: "Nebraska", value: "NE", isSelected: false},
            // {label: "Nevada", value: "NV", isSelected: false},
            // {label: "New Hampshire", value: "NH", isSelected: false},
            // {label: "New Jersey", value: "NJ", isSelected: false},
            // {label: "New Mexico", value: "NM", isSelected: false},
            // {label: "New York", value: "NY", isSelected: false},
            // {label: "North Carolina", value: "NC", isSelected: false},
            // {label: "North Dakota", value: "ND", isSelected: false},
            // {label: "Ohio", value: "OH", isSelected: false},
            // {label: "Oklahoma", value: "OK", isSelected: false},
            // {label: "Oregon", value: "OR", isSelected: false},
            // {label: "Pennsylvania", value: "PA", isSelected: false},
            // {label: "Rhode Island", value: "RI", isSelected: false},
            // {label: "South Carolina", value: "SC", isSelected: false},
            // {label: "South Dakota", value: "SD", isSelected: false},
            // {label: "Tennessee", value: "TN", isSelected: false},
            // {label: "Texas", value: "TX", isSelected: false},
            {label: "Utah", value: "UT", isSelected: false},
            // {label: "Vermont", value: "VT", isSelected: false},
            // {label: "Virginia", value: "VA", isSelected: false},
            // {label: "Washington", value: "WA", isSelected: false},
            // {label: "West Virginia", value: "WV", isSelected: false},
            // {label: "Wisconsin", value: "WI", isSelected: false},
            // {label: "Wyoming", value: "WY", isSelected: false},
        ]
    },
    {
        type: "select",
        key: "county",
        label: "County",
        options: [
            //TODO: counties should be in DB
        ]
    },
    {
        type: "number",
        key: "longitude",
        label: "Longitude",
        minValue: null,
        maxValue: null,
    },
    {
        type: "number",
        key: "latitude",
        label: "Latitude",
        minValue: null,
        maxValue: null, 
    },
    {
        type: "select",
        key: "city",
        label: "City",
        options: [
            //TODO: city should be in DB
        ]
    },
    {
        type: "select",
        key: "zip",
        label: "Zip",
        options: [
            //TODO: zip should be in DB
        ]
    },
    {
        type: "select",
        key: "schoolDistrict",
        label: "School District",
        options: [
            //TODO: schoolDistrict should be in DB
        ]
    },
    {
        type: "select",
        key: "srHigh",
        label: "SR High",
        options: [
            //TODO: srHigh should be in DB
        ]
    }

]

// export const propertyListStatuses: [[key: number]: string] = [
//     {key: 1, value: "Active"},
//     {key: 2, value: "Expired"},
//     3: "Sold",
//     4: "Under Contract",
//     5: "Withdrawn",
//     7: "Off Market",
//     9: "Auction",
//     10: "Not Active",
//     11: "Unknown",
// ]

export const propertyListStatuses= [
    { label: "Active", value: 1 },
    { label: "Expired", value: 2 },
    { label: "Sold", value: 3 },
    { label: "Under Contract", value: 4 },
    { label: "Withdrawn", value: 5 },
    { label: "Off Market", value: 7 },
    { label: "Auction", value: 9 },
    { label: "Not Active", value: 10 },
    { label: "Unknown", value: 11 },
]

export const propertyStates = [
    // { label: "AL", value: "AL" },
    // { label: "AK", value: "AK" },
    // { label: "AZ", value: "AZ" },
    // { label: "AR", value: "AR" },
    // { label: "CA", value: "CA" },
    // { label: "CO", value: "CO" },
    // { label: "CT", value: "CT" },
    // { label: "DE", value: "DE" },
    // { label: "FL", value: "FL" },
    // { label: "GA", value: "GA" },
    // { label: "HI", value: "HI" },
    // { label: "ID", value: "ID" },
    // { label: "IL", value: "IL" },
    // { label: "IN", value: "IN" },
    // { label: "IA", value: "IA" },
    // { label: "KS", value: "KS" },
    // { label: "KY", value: "KY" },
    // { label: "LA", value: "LA" },
    // { label: "ME", value: "ME" },
    // { label: "MD", value: "MD" },
    // { label: "MA", value: "MA" },
    // { label: "MI", value: "MI" },
    // { label: "MN", value: "MN" },
    // { label: "MS", value: "MS" },
    // { label: "MO", value: "MO" },
    // { label: "MT", value: "MT" },
    // { label: "NE", value: "NE" },
    // { label: "NV", value: "NV" },
    // { label: "NH", value: "NH" },
    // { label: "NJ", value: "NJ" },
    // { label: "NM", value: "NM" },
    // { label: "NY", value: "NY" },
    // { label: "NC", value: "NC" },
    // { label: "ND", value: "ND" },
    // { label: "OH", value: "OH" },
    // { label: "OK", value: "OK" },
    // { label: "OR", value: "OR" },
    // { label: "PA", value: "PA" },
    // { label: "RI", value: "RI" },
    // { label: "SC", value: "SC" },
    // { label: "SD", value: "SD" },
    // { label: "TN", value: "TN" },
    // { label: "TX", value: "TX" },
    { label: "UT", value: "UT" },
    // { label: "VA", value: "VA" },
    // { label: "WA", value: "WA" },
    // { label: "WV", value: "WV" },
    // { label: "WI", value: "WI" },
    // { label: "WY", value: "WY" },
]

export interface PropertySearchDto {
    minPrice: string;
    maxPrice: string;
    minBedrooms: string;
    maxBedrooms: string;
    minBathrooms: string;
    maxBathrooms: string;
    minYearBuilt: string;
    maxYearBuilt: string;
    minDaysOnMarket: string;
    maxDaysOnMarket: string;
    minNsCoordinate: string;
    minNsDir: string;
    maxNsCoordinate: string;
    maxNsDir: string;
    minEwCoordinate: string;
    minEwDir: string;
    maxEwCoordinate: string;
    maxEwDir: string;
    remarks: string;
    minSqFt: string;
    maxSqFt: string;
    minAcres: string;
    maxAcres: string;
    stateId: string;
    countyId: string;
    cityIds: string[];
    states: string[];
    counties: string[];
    cities: string[];
    zipCodes: string[];
    propertySubTypeIds: string[];
    listStatusIds: string[];
    schoolDistrictIds: string[];
    srHighIds: string[];
    mlsAreaIds: string[];
    nbhdIds: string[];
    subdivisionIds: string[];
    subdivision: string;
    text: string;
    scope: string;
    isShortSale: boolean;
    isApprovedShortSale: boolean;
    searchType: string;
    fullAddressOnly: string;
    ownerOnly: string;
    taxIdOnly: string;
    mlsNumberOnly: string;
    minSaleAmt: string;
    maxSaleAmt: string;
    minSaleDate: string;
    maxSaleDate: string;
    minLoanAmt: string;
    maxLoanAmt: string;
    minLoanBalance: string;
    maxLoanBalance: string;
    minLoanDate: string;
    maxLoanDate: string;
    minLoanRate: string;
    maxLoanRate: string;
    minLoanToValue: string;
    maxLoanToValue: string;
    minLoanToPrice: string;
    maxLoanToPrice: string;
    minNoticeOfDefault: string;
    maxNoticeOfDefault: string;
    minListDate: string;
    maxListDate: string;
    minExpirationDate: string;
    maxExpirationDate: string;
    parcelTitleRecordParty: string;
    portfolioId?: number;
    portfolioName?: string;
    userStateId?: number;
    userCountyId?: number;
    limit: number;
    offset: number;
    propertyTypes: string[];
    propertySubTypes: string[];
    lenders: string[];
    listStatuses: string[];
}

export function emptyPropertySearchDto(): PropertySearchDto {
    return {
        minPrice: null,
        maxPrice: null,
        minBedrooms: null,
        maxBedrooms: null,
        minBathrooms: null,
        maxBathrooms: null,
        minYearBuilt: null,
        maxYearBuilt: null,
        minDaysOnMarket: null,
        maxDaysOnMarket: null,
        minNsCoordinate: null,
        minNsDir: null,
        maxNsCoordinate: null,
        maxNsDir: null,
        minEwCoordinate: null,
        minEwDir: null,
        maxEwCoordinate: null,
        maxEwDir: null,
        remarks: null,
        minSqFt: null,
        maxSqFt: null,
        minAcres: null,
        maxAcres: null,
        stateId: null,
        countyId: null,
        cityIds: [],
        states: [],
        counties: [],
        cities: [],
        zipCodes: [],
        propertySubTypeIds: [],
        listStatusIds: [],
        schoolDistrictIds: [],
        srHighIds: [],
        mlsAreaIds: [],
        nbhdIds: [],
        subdivisionIds: [],
        subdivision: null,
        text: null,
        scope: null,
        isShortSale: null,
        isApprovedShortSale: null,
        searchType: null,
        fullAddressOnly: null,
        ownerOnly: null,
        taxIdOnly: null,
        mlsNumberOnly: null,
        minSaleAmt: null,
        maxSaleAmt: null,
        minSaleDate: null,
        maxSaleDate: null,
        minLoanAmt: null,
        maxLoanAmt: null,
        minLoanBalance: null,
        maxLoanBalance: null,
        minLoanDate: null,
        maxLoanDate: null,
        minLoanRate: null,
        maxLoanRate: null,
        minLoanToValue: null,
        maxLoanToValue: null,
        minLoanToPrice: null,
        maxLoanToPrice: null,
        minNoticeOfDefault: null,
        maxNoticeOfDefault: null,
        minListDate: null,
        maxListDate: null,
        minExpirationDate: null,
        maxExpirationDate: null,
        parcelTitleRecordParty: null,
        portfolioId: null,
        portfolioName: null,
        userStateId: null,
        userCountyId: null,
        limit: null,
        offset: null,
        propertyTypes: [],
        propertySubTypes: [],
        lenders: [],
        listStatuses: [],
    }
}
