import { Button, Table, Image, ConfigProvider, Empty } from 'antd';
import React from 'react';
import { HomeTwoTone } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { GenericTableProps } from '../../types/Table.def';
import { TableCell } from '../../types/Table.def';

const IS_MOBILE = window.innerWidth < 768;

export const GenericTable: React.FC<GenericTableProps> = ({ data, emptyMessage = null, loading = false, onSort }) => {
    const columns = data.headers.map((header, index) => ({
        title: header.text,
        dataIndex: index.toString(),
        key: index.toString(),
        sorter: !!onSort && !!header.text,
        render: (cell: TableCell) => {
            if (index === 0) {
                if (cell.imageUrl) {
                    return (
                        <Image
                            src={cell.imageUrl}
                            alt="Property"
                            fallback='https://trueavm-app-images.s3.us-east-1.amazonaws.com/house_v1.png'
                            style={{
                                width: '90px',
                                height: '67.5px',
                                objectFit: 'cover',
                                borderRadius: '10px'
                            }}
                            preview={false}
                        />
                    );
                }
                return <HomeTwoTone style={{ fontSize: 55, marginLeft: '10px' }} />;
            }

            let displayText = cell.text?.substring(0, 30);
            if(cell.text?.length > 30) {
                displayText += '...';
            }

            if (cell.internalLinkUrl) {
                return (
                    <Link to={cell.internalLinkUrl}>
                        <Button type="link" style={{ textDecoration: 'underline' }}>
                            {displayText}
                        </Button>
                    </Link>
                );
            }

            if (cell.externalLinkUrl) {
                return (
                    <a href={cell.externalLinkUrl} target="_blank" rel="noopener noreferrer">
                        {displayText}
                    </a>
                );
            }

            return displayText?.trim() ? displayText : '-';
        }
    }));

    const dataSource = data.rows.map((row, rowIndex) => ({
        key: rowIndex,
        ...row.reduce((acc, cell, cellIndex) => ({
            ...acc,
            [cellIndex]: cell
        }), {})
    }));

    const renderEmpty = () => {
        return (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyMessage || 'No results found'} />
        )
    }

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter && onSort) {
            console.log(sorter);
            onSort({
                text: sorter?.column?.title || null,
                desc: !!sorter?.order ? sorter.order === 'descend' : null
            });
        }
    };

    return (
        <ConfigProvider renderEmpty={renderEmpty}>
            <Table
                dataSource={dataSource}
                columns={columns}
                loading={loading}
                size="small"
                pagination={false}
                scroll={{ x: true }}
                onChange={handleTableChange}
            />
        </ConfigProvider>
    );
};
