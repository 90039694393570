import { Layout, Pagination, PaginationProps, Select, Space } from 'antd';
import { useEffect, useState } from 'react';

import Search, { SearchProps } from 'antd/es/input/Search';
import { Content, Header } from 'antd/es/layout/layout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useHttpService } from '../../../../hooks/UseHttpService';
import { PropertySearchOptions } from '../../../../types/PropertyGetter';
import { RestResponse } from '../../../../types/RestResponse.def';
import { TableCell, TableHeader } from '../../../../types/Table.def';
import { GenericTable } from '../../../GenericTable/GenericTable';
const { Option } = Select;

interface PropertySearchProps {
    defaultSearch: PropertySearchOptions;
    _searchType: "address" | "ownerName" | "text" | "listNumber" | "taxId";
    dataType: "properties" | "saleActivity" | "titleActivity";
}


export default function PropertySearch({ defaultSearch, _searchType, dataType }: PropertySearchProps) {

    const PAGE_SIZE = 30;

    const navigate = useNavigate();
    const http = useHttpService();

    const [headers, setHeaders] = useState<TableHeader[]>([]);
    const [rowsMap, setRowsMap] = useState<{ [key: number]: TableCell[][] }>({ 1: [] });
    const [total, setTotal] = useState(0);
    const [pageNumber, _setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [searchCriteria, _setSearchCriteria] = useState<{ key: string, value: string }>({ key: null, value: null });
    const [searchParams, setSearchParams] = useSearchParams();
    const { key, value } = Object.fromEntries(searchParams);
    const [searchType, setSearchType] = useState('address');

    useEffect(() => {
        if(_searchType) {
            console.log("searchType", _searchType);
            setSearchType(_searchType);
            setSearchCriteria(_searchType, "");
        } else {
            console.log("no searchType");
            setSearchType("address");
            setSearchCriteria("address", "");
        }
    }, [_searchType])

    useEffect(() => {
        console.log("dataType", dataType);
        setHeaders([]);
        setRowsMap({ 1: [] });
        setTotal(0);
        _setPageNumber(1);
        setLoading(false);
        
        if (key && value) {
            searchByText(key, value, 1);
        }
    }, [dataType]);

    const setRowsAtIndex = (index: number, rows: TableCell[][]) => {
        setRowsMap(prev => ({ ...prev, [index]: rows }));
    }

    const setPageNumber = (index: number) => {
        if (index < 1) return;
        if (!rowsMap[index]) {
            setRowsAtIndex(index, []);
        }
        _setPageNumber(index);
    }

    useEffect(() => {
        if (!!key && !!value) {
            setSearchCriteria(key, value);
            setSearchType(key);
        } else if (defaultSearch) {
            const searchKey = Object.entries(defaultSearch).find(([key, value]) => value !== null)?.[0];
            if (searchKey) {
                setSearchCriteria(searchKey, defaultSearch[searchKey]);
                setSearchType(searchKey);
            }
        }
    }, [defaultSearch, key, value]);

    const setSearchCriteria = (key?: string, value?: string) => {
        _setSearchCriteria({ key, value });
        setPageNumber(1);
        searchByText(key, value);
    }


    const allowExport = (): boolean => {
        return !!key && !!value && !!rowsMap[pageNumber] && rowsMap[pageNumber].length > 0;
    }

    const searchByText = async (key: string, value: string, page: number = 1) => {
        console.log("searchByText", key, value, page);
        if (!key || !value) return;
        try {
            setLoading(true);
            http.cancelRequestsByUrlPattern(`/${dataType}/search?`);
            let url = `/${dataType}/search?${key}=${value}`;
            url += `&limit=${PAGE_SIZE}&offset=${(page - 1) * PAGE_SIZE}`;
            let res: RestResponse = await http.get(url);
            if (res.success) {
                setTotal(res.data.total);
                setHeaders(res.data.headers);
                setRowsAtIndex(pageNumber, res.data.rows);
            }
        } finally {
            setLoading(false);
        }
    }

    const handleExport = async () => {
        if (!!key && !!value) return exportSearchResults();
    }

    const exportSearchResults = async () => {
        await http.post(`/${dataType}/exportSearched?${key}=${value}`, {}, {}, true);
    }

    const handleReset = () => {
        http.cancelRequestsByUrlPattern(`/${dataType}`)
        setRowsMap({ 1: [] });
        setSearchCriteria(key, null);
    };

    const handlePageChange: PaginationProps['onChange'] = (page) => {
        window.scrollTo(0, 0);
        setPageNumber(page)
        searchByText(key, value, page);
    }

    const onSearch: SearchProps['onSearch'] = async (value) => {
        searchByText(searchType, value);
    }

    const prefix = (
        <Space>
            <Select defaultValue="address" className="search-type-select" value={searchType || 'address'} onChange={(value) => setSearchType(value)}>
                <Option value="address">Address</Option>
                <Option value="listNumber">List #</Option>
                <Option value="owner">Owner</Option>
                <Option value="taxId">Tax ID</Option>
                <Option value="text">Text</Option>
            </Select>
        </Space>
    );

    return (
        <Layout style={{ background: '#ffffff' }}>
            <Header style={{ padding: 0, background: '#ffffff' }}>
                <div style={{ margin: '0 16px', display: 'grid', gridTemplateColumns: '1fr auto', columnGap: 10 }}>
                    <Search addonBefore={prefix} placeholder="Search..." allowClear onClear={() => handleReset()}
                        onSearch={onSearch} loading={loading}
                        enterButton style={{ paddingTop: 15, marginRight: 5, width: 'minmax(180, 100%)' }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
                        <Pagination
                            current={pageNumber}
                            simple
                            showSizeChanger={false}
                            onChange={handlePageChange}
                            pageSize={PAGE_SIZE}
                            total={total}
                        />
                    </div>
                </div>
            </Header>
            <Content style={{ padding: '0 16px', }}>
                <GenericTable data={{ headers: headers || [], rows: rowsMap[pageNumber] || [] }} loading={loading} />
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px 0' }}>
                    <Pagination
                        current={pageNumber}
                        simple
                        showSizeChanger={false}
                        onChange={handlePageChange}
                        pageSize={PAGE_SIZE}
                        total={total}
                    />
                </div>
            </Content>
        </Layout>
    )
}
