

export interface RestResponse<T = any> {
    success: boolean;
    message: string;
    data?: T;
}

export const emptyRestResponse = <T = any>() => {
    return {
        success: false,
        message: 'No Data',
        data: {} as T
    } as RestResponse<T>;
}