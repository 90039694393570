import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { App, Button, Card, Flex, Form, Input, Typography } from "antd";
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';
import { useHttpService } from '../../hooks/UseHttpService';
import { toast_error, toast_warn } from "../../shared/shared-functions";
import './login.css';


const LoginPage: React.FC = () => {

    const {resetAuth} = useAppContext();
    const http = useHttpService();
    const [params] = useSearchParams();
    const {message} = Object.fromEntries(params);   

    useEffect(() => {
        if(message === "unauthorized") {
            toast_warn("Please log in to access this page.");
        }
    }, [message])


    

    const onFinish = async (values: any) => {
        if (!!values?.password && !!values?.username) {
            let result = await http.post(`/authentication`, { username: values.username, password: values.password });
            if (!!result.success) {
                resetAuth(result.data);
            } else {
                toast_error("Incorrect username or password.")
            }
        } else {
            toast_error("Please enter both a username and password to log in.");
        }
    };

    const cardStyle: React.CSSProperties = {
        // width: '50vw',
        minWidth: '375px',
        maxWidth: '900px',
        width: '55vw',
    };

    const formStyle: React.CSSProperties = {
        width: '100%',
    }


    return (
        <div className="login-container">
            <Card style={cardStyle} styles={{ body: { padding: 0, overflow: 'hidden' } }}>
                <Flex justify="space-between" gap={10}>
                    {/* <HomeOutlined style={{ fontSize: '160px', padding: 40, color: '#ccc' }} /> */}
                    <Flex vertical align="flex-start" justify="space-between" style={{ padding: 32, width: "100%" }}>
                        <Typography.Title level={2} style={{marginBottom: 10}}>
                            Welcome to TrueAVM.
                        </Typography.Title>
                        <Typography.Title level={4} style={{marginTop: 0}}>
                            Log in to receive full access.
                        </Typography.Title>
                        <Form
                            style={formStyle}
                            name="normal_login"
                            className="login-form"
                            onFinish={onFinish}
                        >
                            <Form.Item
                                name="username"
                                rules={[{ required: true, message: 'Please input your Username!' }]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Please input your Password!' }]}
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button" style={{width: '100%'}}>
                                    Log in
                                </Button>
                                <br/>
                                <br/>
                                <Link to="/reset-password">Forgot password?</Link>
                                <br />
                                Or <a href="mailto:info@trueavm.com">contact us</a> to register now!
                                {/* TODO: contact us to register */}
                            </Form.Item>
                        </Form>
                    </Flex>
                </Flex>
            </Card>
        </div>
    )
}

export default function Login() {
    return (
        <App style={{height: '85%', display: 'grid', gridTemplateColumns: "1fr", placeContent: 'center' }}>
            <LoginPage />
        </App>
    )
}