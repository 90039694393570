import { Button, Form, Input, Select, Typography } from "antd";
import { useHttpService } from "../../../hooks/UseHttpService";
import { checkValidEmail, toast_error, toast_success, toast_warn, validatePassword } from "../../../shared/shared-functions";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../context/AppContext";

export default function ManageAccount() {

  const http = useHttpService();
  const {resetAuth, currentWorkspaceId} = useAppContext();
  
  const storage = localStorage;
  const [options, setOptions] = useState<Array<{ value: string, label: string }>>([]);
  const [loading, setLoading] = useState(false);

  const [emailForm] = Form.useForm();
  const [passwordForm] = Form.useForm();

  useEffect(() =>  {
    fetchWorkspaces();
  }, []);

  const updateEmail = async (values: any) => {
    if(values.email === storage.getItem("email")) {
      toast_warn("The provided email is already associated with your account.");
      return;
    }
    if (!checkValidEmail(values.email)) {
      toast_error("Please enter a valid email to attach to your account.");
      return;
    }
    const result = await http.get(`/account/updateUsername?newUsername=${encodeURIComponent(values.email)}`);
    if (result.success) {
      storage.setItem("email", values.email);
      toast_success(result.message);
      emailForm.resetFields();
    } else
      toast_warn(result.message);
  }

  const updatePassword = async (values: any) => {
    let isValid: boolean = validatePassword(values.password, values.confirmPassword);
    if (!isValid) {
      return;
    }
    const result = await http.post(`/account/updatePassword`, {currentPassword: values.currentPassword, newPassword: values.password});
    if (result.success) {
      toast_success(result.message);
      passwordForm.resetFields();
    } else
      toast_error(result.message);
  }

  const fetchWorkspaces = async () => {
    const result = await http.get('/account/getWorkspaces');
    setLoading(true);
    if (result.success) {
      setOptions(result.data);
    } else {
      toast_error("Failed fetching your workspaces")
    }
    setLoading(false);
  }

  const switchWorkspace = async (value: any) => {
    const result = await http.get(`/account/switchWorkspace?newWorkspaceId=${value}`);
    if (!!result.success){
      const selectedOption = options.find(option => option.value === value);
      toast_success(`Welcome to ${selectedOption?.label || "a new workspace"}!`);
      resetAuth(result.data);
    }
  }

  return (
    <>
      <Typography.Title level={2} style={{ width: "100%", paddingBottom: '.5rem', marginBottom: '.5rem', borderBottom: '1px solid #ccc', margin: 0, color: 'Black' }}>
        Account
      </Typography.Title>
      {options != null && options.length > 1 ?
        <Form.Item label="Workspace" name="workspace" layout="vertical">
          <Select 
            placeholder="Please select an option" 
            options={options} 
            loading={loading}
            onChange={switchWorkspace}
            defaultValue={currentWorkspaceId}
          >
          </Select>
        </Form.Item>
      : <></>}
      <Form form={emailForm} onFinish={updateEmail} layout="vertical">
        <Form.Item
          label="Email"
          name="email"
        >
          <Input placeholder={"johndoe@email.com"} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update Email
          </Button>
        </Form.Item>
      </Form>
      <Form form={passwordForm} onFinish={updatePassword} layout="vertical">
        <Form.Item label="Current Password" name="currentPassword">
          <Input.Password />
        </Form.Item>
        <Form.Item label="New Password" name="password">
          <Input.Password />
        </Form.Item>
        <Form.Item label="Confirm New Password" name={"confirmPassword"}>
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update Password
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};