import { Tabs } from 'antd';
import PropertyFilter from './PropertyFilter/PropertyFilter';
import PropertySearch from './PropertySearch/PropertySearch';
import './PropertySearchAndFilters.css';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useHttpService } from '../../../hooks/UseHttpService';
import { PropertyGetter } from '../../../types/PropertyGetter';

interface PropertySearchAndFiltersProps {
    dataType: "properties" | "saleActivity" | "titleActivity";
}

export default function PropertySearchAndFilter({ dataType }: PropertySearchAndFiltersProps) {

    const [activeTab, setActiveTab] = useState('search');
    const [searchParams] = useSearchParams();
    const { key, value } = Object.fromEntries(searchParams);
    const http = useHttpService();
    const [userSearchInfo, setUserSearchInfo] = useState<PropertyGetter>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        http.get<PropertyGetter>(`/${dataType}/getter`).then((res) => {
            setUserSearchInfo(res.data);
            setActiveTab(res.data.tab);
        }).finally(() => {
            setLoading(false);
        });
    }, [])

    useEffect(() => {
        if(key && value) {
            setActiveTab('search');
        } else {
            setActiveTab('filter');
        }
    }, [key, value])

    let items = [
        {
            key: 'search',
            label: 'Search',
            children: loading ? null : <PropertySearch dataType={dataType} defaultSearch={userSearchInfo?.search} _searchType={userSearchInfo.searchType}/>
        },
        {
            key: 'filter',
            label: 'Filter',
            children: loading ? null : <PropertyFilter dataType={dataType} defaultFilters={userSearchInfo?.filter} />
        }
    ]


    return(
        <Tabs type="card" style={{ marginTop: '16px', marginLeft: 8 }} items={items} activeKey={activeTab} onChange={setActiveTab} />
    )
}
