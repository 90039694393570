import {
    DownloadOutlined,
    FilterOutlined
} from '@ant-design/icons';
import { Button, Layout, Modal, Pagination, PaginationProps, Tag } from 'antd';
import { useEffect, useRef, useState } from 'react';

import { Content, Header } from 'antd/es/layout/layout';
import { useNavigate } from 'react-router-dom';
import { useHttpService } from '../../../../hooks/UseHttpService';
import { camelCaseToNormalString } from '../../../../shared/shared-functions';
import { PropertySearchDto, emptyPropertySearchDto } from '../../../../types/PropertyFilter.def';
import { TableCell, TableHeader } from '../../../../types/Table.def';
import { GenericTable } from '../../../GenericTable/GenericTable';
import PropertySearchFilters from '../../../PropertySearch/PropertySearchFilters/PropertySearchFilters';
import './PropertyFilter.css';
import { RestResponse } from '../../../../types/RestResponse.def';

interface PropertyFilterProps {
    defaultFilters: PropertySearchDto;
    dataType: "properties" | "saleActivity" | "titleActivity";
}

export default function PropertyFilter({ defaultFilters, dataType }: PropertyFilterProps) {

    const e = () => {
        console.log("filters", filters);
        console.log("rowsMap", rowsMap);
    }

    const PAGE_SIZE = 30;

    const navigate = useNavigate();
    const http = useHttpService();
    const [open, setOpen] = useState(false);
    const [filters, _setFilters] = useState<PropertySearchDto>(defaultFilters || emptyPropertySearchDto());
    const [filterCount, setFilterCount] = useState(0);
    const [headers, setHeaders] = useState<TableHeader[]>([]);
    const [rowsMap, setRowsMap] = useState<{ [key: number]: TableCell[][] }>({ 1: [] });
    const [sort, _setSort] = useState<TableHeader | null>(null);
    const [total, setTotal] = useState(0);
    const [pageNumber, _setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);

    const isLoadingRef = useRef(false);

    useEffect(() => {
        console.log("dataType", dataType);
        // Reset all relevant state when dataType changes
        setFilters(emptyPropertySearchDto());
        setHeaders([]);
        setRowsMap({ 1: [] });
        setTotal(0);
        _setPageNumber(1);
        setLoading(false);
        
        // If there's an existing search, re-run it for the new dataType
        runDefaultSearch();
    }, [dataType]);

    const setRowsAtIndex = (index: number, rows: TableCell[][]) => {
        setRowsMap(prev => ({ ...prev, [index]: rows }));
    }

    const setPageNumber = (index: number) => {
        if (index < 1) return;
        if (!rowsMap[index]) {
            setRowsAtIndex(index, []);
        }
        _setPageNumber(index);
    }

    const setSort = (sort: TableHeader) => {
        _setSort(sort);
        setPageNumber(1);
        setRowsMap({ 1: [] });
    };

    const setFilters = (newFilters) => {
        _setFilters(newFilters);
        localStorage.setItem(`${dataType}SearchFilters`, JSON.stringify(newFilters));
        setFilterCount(Object.values(newFilters).filter(val => 
            val !== null && 
            val !== "" && 
            val !== undefined && 
            !(Array.isArray(val) && val.length === 0) &&
            !(typeof val === 'object' && !Array.isArray(val) && Object.keys(val).length === 0)
        ).length);
        setPageNumber(1);
    };
    

    useEffect(() => {
        if(hasValidFilters(filters)) {
            runSearch(filters, pageNumber, sort);
        }
    }, [filters, pageNumber, sort]);    

    useEffect(() => {
        if (defaultFilters && hasValidFilters(defaultFilters)) {
            runDefaultSearch();
        }
    }, [defaultFilters]);

    const runDefaultSearch = async (currentPage: number = 1) => {
        if (!!defaultFilters && hasValidFilters(defaultFilters)) {
            const newFilters = { ...emptyPropertySearchDto(), ...defaultFilters };
            setFilters(newFilters);
            await runSearch({ ...newFilters, offset: (currentPage - 1) * PAGE_SIZE, limit: PAGE_SIZE }, currentPage);
        }
    }


    const runSearch = async (_filters: PropertySearchDto = filters, currentPage: number = 1, sort: TableHeader | null = null) => {
        if (isLoadingRef.current) return;
        isLoadingRef.current = true;
        setLoading(true);
        try {
            if (!(rowsMap[currentPage] && rowsMap[currentPage].length > 0)) {
                let obj = { ...filters, offset: (currentPage - 1) * PAGE_SIZE, limit: PAGE_SIZE };
                http.cancelRequestsByUrlPattern(`/${dataType}/filter`);
                let url = `/${dataType}/filter`;
                if(!!sort?.text) {
                    url += "?sortHeader=" + sort?.text + "&desc=" + sort?.desc;
                }
                let res: RestResponse = await http.post(url, obj);
                if (res.success) {
                    setTotal(res.data.total);
                    setHeaders(res.data.headers);
                    setRowsAtIndex(pageNumber, res.data.rows);
                }
            }
        } finally {
            setLoading(false);
            isLoadingRef.current = false;
        }
    }

    const hasValidFilters = (_filters: PropertySearchDto = filters): boolean => {
        for (const [key, value] of Object.entries(_filters)) {
            if(key === "offset" || key === "limit") continue;
            if (value !== null &&
                value !== undefined &&
                value !== '' &&
                !(Array.isArray(value) && value.length === 0) &&
                !(typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0)) {
                return true;
            }
        }
        return false;
    }

    const allowExport = (): boolean => {
        return hasValidFilters() && dataType === "properties";
    }

    const handleFilterSubmit = (formData) => {
        setFilters(formData);
        setOpen(false);
        setPageNumber(1);
        setRowsMap({ 1: [] });
    };
    

    const handleExport = async () => {
        if (hasValidFilters()) return exportFilteredProperties();
    }

    const exportFilteredProperties = async () => {
        await http.post(`/${dataType}/exportFiltered`, filters, {}, true);
    }

    const showModal = () => {
        setOpen(true);
    };

    const handleReset = () => {
        http.cancelRequestsByUrlPattern(`/${dataType}`)
        setRowsMap({ 1: [] });
        setTotal(0);
        handleFilterSubmit(emptyPropertySearchDto());
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handlePageChange: PaginationProps['onChange'] = (page) => {
        window.scrollTo(0, 0);
        setPageNumber(page);
    };
    

    const renderFilterTags = () => {
        return Object.entries(filters).map(([key, value]) => {
            if (value !== undefined && value !== null && value !== "" &&
                !(Array.isArray(value) && value.length === 0) &&
                !(typeof value === 'object' && Object.keys(value).length === 0)) {
                return (
                    <Tag key={key} closable onClose={() => handleRemoveFilter(key)} style={{ marginRight: '8px' }}>
                        <b>{camelCaseToNormalString(key)}:</b> {Array.isArray(value) ? value.join(', ') : value.toString()}
                    </Tag>
                );
            }
            return null;
        }).filter(Boolean);
    };

    const handleRemoveFilter = (key: string) => {
        const newFilters = { ...filters };
        delete newFilters[key];
        setFilters(newFilters);
        setPageNumber(1);
        setRowsMap({ 1: [] });
    };
    

    const emptyMessage = () => {
        if(hasValidFilters()) {
            return 'No results found';
        }
        return 'Apply filters to see results!';
    }

    return (
        <Layout style={{ background: '#ffffff' }}>
            <Header style={{ padding: 0, background: '#ffffff' }}>
                <div className='filter-container'>
                    <div className='fixed-tags'>
                        {rowsMap[pageNumber] && rowsMap[pageNumber].length > 0 && allowExport() && (
                            <Tag onClick={handleExport} style={{ marginRight: '8px', cursor: 'pointer' }}>
                                <DownloadOutlined /> Export results
                            </Tag>
                        )}
                        <Tag color={filterCount > 0 ? 'blue' : 'default'} onClick={showModal} style={{ marginRight: '8px', cursor: 'pointer' }}>
                            <FilterOutlined /> Filters ({filterCount})
                        </Tag>
                        <Modal
                            open={open}
                            title="Search Filters"
                            onCancel={handleCancel}
                            footer={[
                                <Button key="reset" onClick={handleReset}>
                                    Reset
                                </Button>,
                                <Button key="submit" type="primary" form="propertySearchFiltersForm" htmlType="submit">
                                    Submit
                                </Button>
                            ]}
                        >
                            <PropertySearchFilters onSubmit={handleFilterSubmit} existingFilters={filters} />
                        </Modal>
                    </div>
                    <div className='scrollable-tags-container'>
                        <div className='scrollable-tags'>
                            {renderFilterTags()}
                        </div>
                    </div>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Pagination
                                style={{ marginTop: 20 }}
                                current={pageNumber}
                                simple
                                showSizeChanger={false}
                                onChange={handlePageChange}
                                pageSize={PAGE_SIZE}
                                total={total}
                            />
                        </div>
                    </div>
                </div>
            </Header>
            <Content style={{ padding: '0 16px',  }}>
                <GenericTable emptyMessage={emptyMessage()} data={{ headers: headers || [], rows: rowsMap[pageNumber] || [] }} loading={loading} onSort={setSort} />
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px 0' }}>
                        <Pagination
                            current={pageNumber}
                            simple
                            showSizeChanger={false}
                            onChange={handlePageChange}
                            pageSize={PAGE_SIZE}
                            total={total}
                    />
                </div>
                {/* <Button onClick={e}>Test BUtton</Button> */}
            </Content>
        </Layout>
    )
}
