import { Collapse, CollapseProps, Form, Input, InputNumber, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { emptyPropertySearchDto, propertyListStatuses, PropertySearchDto, propertyStates } from "../../../types/PropertyFilter.def";
import "./PropertySearchFilters.css";

interface PropertySearchFiltersProps {
    onSubmit: (formData: any) => void;
    existingFilters: PropertySearchDto;
}

const FilterInput = ({ label, name, onChange, type = "number", options = [] }) => {
    const form = Form.useFormInstance();
    const value = Form.useWatch(name, form);

    return (
        <>
        <Typography.Text style={{fontSize: "16px", fontWeight: "500"}}>{label}</Typography.Text>
        <Form.Item name={name}>
            {type === "number" ? (
                <InputNumber placeholder="--" onChange={(value) => onChange(name, value)} />
            ) : type === "select" ? (
                <Select options={options} placeholder="Select..." onChange={(value) => onChange(name, value)} />
            ) : type === "multi-select" ? (
                <Select mode="multiple" options={options} placeholder="Select..." onChange={(value) => onChange(name, value)} />
            ) : type === "tags" ? (
                <Select 
                    mode="tags" 
                    placeholder="Type and press enter..." 
                    onChange={(value) => onChange(name, value)}
                />
            ) : (
                <Input placeholder="--" onChange={(e) => onChange(name, e.target.value)} />
            )}
        </Form.Item>
        </>
    );
};

const FilterRangeInput = ({ label, minName, maxName, onChange, type = "number" }) => {
    return (
        <>
        <Typography.Text style={{fontSize: "16px", fontWeight: "500"}}>{label}</Typography.Text>
        <div style={{ display: "flex", gap: "10px" }}>
            <Form.Item label={"Min"} name={minName}>
                <InputNumber placeholder="--" onChange={(value) => onChange(minName, value)} />
            </Form.Item>
            <Form.Item label={"Max"} name={maxName}>
                <InputNumber placeholder="--" onChange={(value) => onChange(maxName, value)} />
            </Form.Item>
        </div>
        </>
    );
};

export default function PropertySearchFilters({ onSubmit, existingFilters }: PropertySearchFiltersProps) {
    const [form] = Form.useForm();

    const [filters, setFilters] = useState<PropertySearchDto>(() => ({
        ...emptyPropertySearchDto(),
        ...existingFilters
    }));

    const handleSubmit = (values) => {
        const fullFilters = { ...filters, ...values };
        onSubmit(fullFilters);
    };

    useEffect(() => {
        if (existingFilters && Object.keys(existingFilters).length > 0) {
            const newFilters = { ...emptyPropertySearchDto(), ...existingFilters };
            setFilters(newFilters);
            form.setFieldsValue(newFilters);
        }
    }, [existingFilters, form]);

    const updateFilters = (key: keyof PropertySearchDto, value: any) => {
        setFilters((prevFilters) => {
            const newFilters = { ...prevFilters, [key]: value };
            form.setFieldsValue({ [key]: value });
            return newFilters;
        });
    };

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Property Characteristics',
            children: <>
                <FilterRangeInput label="Bedrooms" minName="minBedrooms" maxName="maxBedrooms" onChange={updateFilters} />
                <FilterRangeInput label="Bathrooms" minName="minBathrooms" maxName="maxBathrooms" onChange={updateFilters} />
                <FilterRangeInput label="Square Footage" minName="minSqFt" maxName="maxSqFt" onChange={updateFilters} />
                <FilterRangeInput label="Lot Size (Acres)" minName="minAcres" maxName="maxAcres" onChange={updateFilters} />
                <FilterRangeInput label="Year Built" minName="minYearBuilt" maxName="maxYearBuilt" onChange={updateFilters} />
                {/* <FilterInput label="Lender" name="lenders" onChange={updateFilters} type="multi-select" options={propertyLenders} /> */}
                {/* TODO: add lender filter options. Probably will come from the getter filterMenu */}
            </>,
        },
        //TODO: missing property type filter rn
        // {
        //     key: '2',
        //     label: 'Property Type',
        //     children: <>
        //     </>,
        // },
        {
            key: '2',
            label: 'Listing Information',
            children: <>
               <FilterRangeInput label="Price (USD)" minName="minPrice" maxName="maxPrice" onChange={updateFilters} />
                <FilterRangeInput label="Days on Market" minName="minDaysOnMarket" maxName="maxDaysOnMarket" onChange={updateFilters} />
                <FilterInput label="List Status" name="listStatusIds" onChange={updateFilters} type="multi-select" options={propertyListStatuses} />
            </>
        },
        // {
        //     key: '4',
        //     label: 'Coordinates',
        //     children: <>
        //         <FilterInput label="Min NS Coordinate" name="minNsCoordinate" value={filters.minNsCoordinate} onChange={updateFilters} />
        //         <FilterInput label="Max NS Coordinate" name="maxNsCoordinate" value={filters.maxNsCoordinate} onChange={updateFilters} />
        //         <FilterInput label="Min EW Coordinate" name="minEwCoordinate" value={filters.minEwCoordinate} onChange={updateFilters} />
        //         <FilterInput label="Max EW Coordinate" name="maxEwCoordinate" value={filters.maxEwCoordinate} onChange={updateFilters} />
        //     </>
        // },
        {
            key: '3',
            label: 'Location',
            children: <>
                <FilterInput label="State" name="state" onChange={updateFilters} type="select" options={propertyStates} />
                <FilterInput label="County" name="county" onChange={updateFilters} type="County" />
                <FilterInput label="City" name="city" onChange={updateFilters} type="text" />
                <FilterInput label="Zip Code" name="zipCodes" onChange={updateFilters} type="tags" />
            </>
        }
    ];

    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            id="propertySearchFiltersForm"
            initialValues={filters}
        >
            <Collapse items={items} defaultActiveKey={['1']}></Collapse>
        </Form>
    );
}